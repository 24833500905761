<script setup>
import { ref, onBeforeMount, onMounted, defineProps, watch } from "vue";
import { i18n } from '../main'; // Import the i18n instance
import axios from 'axios';
import { useRouter } from "vue-router"; // Import useRouter
const props = defineProps(['isDarkMode', 'locale']);
const exist = ref(true);
const ads = ref(true);

const router = useRouter(); // Initialize the router
const API_BASE_URL = ref(process.env.VUE_APP_API_BASE_URL);
const centerednews = ref([]);
const categories = ref([]);
// pagination vars 
const curentPage = ref(1); 
const totalPages = ref(0); 
const itemsPerPage = ref(15);

const totalItems = ref(0); 
const splitIndex = ref(0); 
const sidebar1Posts  = ref([]); 
const sidebar2Posts  = ref([]); 
const adspostbefore = ref([]); 
const adspostafter = ref([]); 
const isLoading = ref(false); // Loading state


const updateLayout = () => {
  exist.value = window.innerWidth > 768;
  ads.value = window.innerWidth > 992;
};

onBeforeMount(updateLayout);

onMounted(() => {
  fetchData(curentPage.value);
  i18n.global.locale = props.locale;
});

watch(() => props.locale, async (newData) => {
  props.locale === newData;
  await fetchData(curentPage.value);
  i18n.global.locale = props.locale;
});

const fetchData = async (page) => {
  isLoading.value = true; // Set loading to true before fetching data
  try {
    const newsResponse = await axios.get(`${API_BASE_URL.value}/api/arabic_posts/?page=${page}`);
    centerednews.value = newsResponse.data.results;
    //get the items of all the page 
    totalItems.value = newsResponse.data.results.length; 
    // get the slice index to divide them to two sidebars
    splitIndex.value = Math.ceil((totalItems.value * 2) / 3);
    

    //get the total pages 
    totalPages.value = Math.ceil(newsResponse.data.count / itemsPerPage.value)//add here the size of the page
    //file the side bar one 
    if (exist.value == true){

      sidebar1Posts.value = centerednews.value.slice(0, splitIndex.value); 
      if (sidebar1Posts.value.length > 5) {

        // Select the first four items
        adspostbefore.value = sidebar1Posts.value.slice(0, 5);
  
        // Select the remaining items
        adspostafter.value = sidebar1Posts.value.slice(5);
      } else {
        // If there are 4 or fewer items, assign all to firstFourItems
        adspostbefore.value = sidebar1Posts.value;
        adspostafter.value = []; // No remaining items
}
      sidebar2Posts.value = centerednews.value.slice(splitIndex.value); 
    }else{
      sidebar1Posts.value = centerednews.value; 
      if (sidebar1Posts.value.length > 5) {
        // Select the first four items
        adspostbefore.value = sidebar1Posts.value.slice(0, 5);
  
        // Select the remaining items
        adspostafter.value = sidebar1Posts.value.slice(5);
      }else {
        // If there are 4 or fewer items, assign all to firstFourItems
        adspostbefore.value = sidebar1Posts.value;
        adspostafter.value = []; // No remaining items
}
    }
    //file the side bar two 
    //get the categories 
    
    const categoriesResponse = await axios.get(`${API_BASE_URL.value}/categories`);
    categories.value = categoriesResponse.data;
  } catch (error) {
    console.error(error);
  } finally{
    isLoading.value = false; 
  }
};

const navigateToNewPage = (general_title, category) => {
  router.push({ name: 'new', params: { categorie:category, slug : general_title } }); // Use router.push for navigation
};
//go to page function 
const goToPage = (pageNum) => {
  if (pageNum > 0 && pageNum <= totalPages.value) {
    curentPage.value = pageNum;
    fetchData(pageNum);
  }
};
</script>

<template>
  <div class="news_categories">
    <div class="container-fluid px-3">
      <h2 class="mt-3">{{ $t('latest news') }}</h2>
      <div class="categorie mt-4 d-flex flex-wrap">
        <div v-for="categorie in categories" :key="categorie.category_id" class="mb-1 d-inline">
          <router-link :to="{ name: 'categorie', params: { categorie: categorie.category_id } }" class="text-decoration-none me-2 text-center px-3">
            {{ categorie.name }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLoading" class="loading-container text-center my-4">
  <div class="spinner"></div>
  <p>{{ $t('loading') }}</p>
</div>
  <div v-else class="row mx-4">
    <!-- Left side news -->
    <div v-if="exist" class="col-md-4 col-lg-3 col p-0">
      <div class="news container-fluid mt-3 row row-cols-1 p-0">
        <div v-for="(item) in sidebar2Posts" :key="item.new.general_title" class="left col mb-5">
          <a @click="navigateToNewPage(item.new.slug, item.new.category.general_name)" style="cursor: pointer;" class="text-decoration-none">
            <div class="card h-100 w-100 overflow-hidden">
              <img :src="item.new.image" :alt="item.new.image_alt" class="card-img-top img-fluid">
              <div class="card-body" :class="{ 'dark-mode': props.isDarkMode, 'light-mode': !props.isDarkMode }">
                <p class="card-text">{{ item.title }}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- Center side news -->
    <div class="col col-md-8 col-lg-6 p-0">
      <div class="news container-fluid mt-3 row row-cols-1 p-0">
        <div v-for="(item01) in adspostbefore" :key="item01.new.general_title" class="col mb-3 center">
          <a @click="navigateToNewPage(item01.new.slug, item01.new.category.general_name)" style="cursor: pointer;" class="text-decoration-none">
            <div class="card overflow-hidden"  >
              <div class="row g-0" :class="{ 'dark-mode': props.isDarkMode, 'light-mode': !props.isDarkMode }">
                <div class="col-md-4">
                  <img :src="item01.new.image" :alt="item01.new.image_alt" class="img-fluid rounded-start w-100 h-100">
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title">{{ item01.title }}</h5>
                    <!-- <p class="card-text"></p> -->
                    <p class="card-text"><small class="">{{ $t('last updated') }} {{ item01.new.created_at }}</small></p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <!-- this is ads section -->
        <div class="col mb-3 center">
          <!-- ads will be here -->
        </div>
        <div v-for="(item01) in adspostafter" :key="item01.new.general_title" class="col mb-3 center">
          <a @click="navigateToNewPage(item01.new.slug, item01.new.category.general_name)" style="cursor: pointer;" class="text-decoration-none">
            <div class="card overflow-hidden"  >
              <div class="row g-0" :class="{ 'dark-mode': props.isDarkMode, 'light-mode': !props.isDarkMode }">
                <div class="col-md-4">
                  <img :src="item01.new.image" :alt="item01.new.image_alt" class="img-fluid rounded-start w-100 h-100" >
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title">{{ item01.title }}</h5>
                    <!-- <p class="card-text"></p> -->
                    <p class="card-text"><small class="">{{ $t('last updated') }} {{ item01.new.created_at }}</small></p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <!-- this is ads section -->
        <div class="col mb-3 center">
          <!-- ads will be here -->
        </div>
      </div>
    </div>
    <!-- Ads side -->
    <div v-if="ads" class="col col-lg-3 p-0">
      <div class="container-fluid mt-3 row row-cols-1 p-0">
        <div  class="col mb-3">
          <!-- the ads section is here  -->
        </div>
      </div>
    </div>
  </div>
  <!-- the prev and the next buttons -->
  <div v-if="!isLoading" class="prev_and_next my-2" :class="{ 'dark-mode': props.isDarkMode, 'light-mode': !props.isDarkMode }">
  <font-awesome-icon class="angle" @click="goToPage(curentPage - 1)" v-if="curentPage > 1"  :icon="['fas', 'angle-right']" />
  <button class="mx-2" :class="{ 'dark-mode': !props.isDarkMode, 'light-mode': props.isDarkMode }" @click="goToPage(curentPage - 1)" :disabled="curentPage <= 1">{{ $t('previous') }}  </button>
  <button class="mx-2" :class="{ 'dark-mode': !props.isDarkMode, 'light-mode': props.isDarkMode }" @click="goToPage(curentPage + 1)" :disabled="curentPage >= totalPages">{{ $t('next') }}</button>
  <font-awesome-icon class="angle" @click="goToPage(curentPage + 1)" v-if="curentPage < totalPages" :icon="['fas', 'angle-left']" />
  </div>
</template>

<style>
/* Add your styles here */
</style>
